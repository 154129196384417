import React from "react";
import {
  EmployeeName,
  TeamImage,
  TeamImageContainer,
} from "../../../styles/team";

export default function TeamImageWrapper({ teamData, onClick }) {
  return (
    <>
      <TeamImageContainer onClick={onClick}>
        <TeamImage src={teamData?.icon} />
      </TeamImageContainer>
      <EmployeeName>{teamData?.name}</EmployeeName>
    </>
  );
}
