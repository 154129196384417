import { Grid } from "@mui/material";
import React from "react";
import { navBarItems } from "../../../constants/navBar";
import {
  Logo,
  NavBarConatiner,
  NavBarListContainer,
  navCenterStyle,
  NavItems,
  NavLogoContainer,
} from "../../../styles/navBar";
import vhiLogo from "../../../assets/Vhi_logo.png";

export default function NavBar() {
  return (
    <>
      <NavBarConatiner>
        <Grid container sx={{ ...navCenterStyle }}>
          <NavLogoContainer item xs={4.5}>
            <Logo
              src={vhiLogo}
              alt="logo"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            />
          </NavLogoContainer>
          <Grid item xs={7.5}>
            <NavBarListContainer>
              {navBarItems?.map((item, index) => {
                return (
                  <NavItems
                    item
                    xs={2}
                    key={index}
                    onClick={() => {
                      window.scrollTo({
                        top: item?.scrollValue,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {item?.label}
                  </NavItems>
                );
              })}
            </NavBarListContainer>
          </Grid>
        </Grid>
      </NavBarConatiner>
    </>
  );
}
