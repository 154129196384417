import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import theme from "./theme";

export const WhyChooseWrap = styled(Grid)({
  width: "70%",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
    width: "100%",
  },
});
export const WhyChooseResponseContainer = styled(Grid)({
  margin: "auto",
  justifyContent: "center",
  display: "flex",
});
export const WhyChooseUsContainer = styled(Box)({
  margin: "40px 10px",
  padding: "20px 10px",
  width: "230px",
  height: "230px",
  transition: "all 0.3s ease-in-out",
  textAlign: "center",
  borderRadius: "8px",
  lineHeight: "25px",
  [theme.breakpoints.down("sm")]: {
    margin: "40px 0px",
  },
  overflow: "hidden",
  background: "rgba(255, 255, 255, 0.27)",
  boxShadow: "0 3px 9px #00000057",
  backdropFilter: "blur(15.4px)",
  border: "1px solid rgba(255, 255, 255, 0.19)",
});
export const WhyChooseIconContainer = styled(Box)({
  width: "100%",
  height: "50px",
  margin: "15px 0px",
});
export const WhyChooseIcon = styled("img")({
  width: "150px",
  height: "50px",
});
export const WhyChooseHeadingText = styled(Box)({
  margin: "25px 10px",
  fontSize: "16px",
  fontFamily: "Rubik",
  textTransform: "uppercase",
});
export const WhyChooseContentText = styled(Box)({
  margin: "10px",
  fontSize: "14px",
  fontFamily: "Rubik",
  lineHeight: "20px",
  textAlign: "center",
});
