import React from "react";
import { ContactUsBgContainer } from "../../styles/contactUs";
import { CopyRightsBgContainer } from "../../styles/copyRights";
import {
  HomeLayOutBgContainer,
  LayoutContainer,
  OurClientsBgContainer,
  WhyChooseUsBgContainer,
} from "../../styles/layOut";
import { TeamBgContainer } from "../../styles/team";
import { TestimonialsBgContainer } from "../../styles/testimonials";
import ContactUs from "../pages/contactUs/ContactUs";
import CopyRights from "../pages/copyRights/CopyRights";
import HomePage from "../pages/home";
import TechnologyExpertise from "../pages/home/TechnologyExpertise";
import NavBar from "../pages/navBar/NavBar";
import OurClients from "../pages/ourClients/OurClients";
import Team from "../pages/team";
import Testimonials from "../pages/testimonials/index";
import WhyChooseUs from "../pages/whyChooseUs/WhyChooseUs";

export default function LayOut() {
  return (
    <LayoutContainer>
      {/* home */}
      <HomeLayOutBgContainer>
        <NavBar />
        <HomePage />
        <TechnologyExpertise />
      </HomeLayOutBgContainer>

      {/* why choose us */}
      <WhyChooseUsBgContainer>
        <WhyChooseUs />
      </WhyChooseUsBgContainer>

      {/* Our Clients */}
      <OurClientsBgContainer>
        <OurClients />
      </OurClientsBgContainer>

      {/* Testimonials */}
      <TestimonialsBgContainer>
        <Testimonials />
      </TestimonialsBgContainer>

      {/* Team */}
      <TeamBgContainer>
        <Team />
      </TeamBgContainer>

      {/* Contact Us */}
      <ContactUsBgContainer>
        <ContactUs />
      </ContactUsBgContainer>

      {/* Copy Rights */}
      <CopyRightsBgContainer>
        <CopyRights />
      </CopyRightsBgContainer>
    </LayoutContainer>
  );
}
