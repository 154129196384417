import styled from "@emotion/styled";
import { Box, Grid, IconButton } from "@mui/material";
import theme from "./theme";
import backgroundImage from "../assets/backgroundImage.png";

export const TeamBgContainer = styled(Box)({
  backgroundColor: "#fff",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
});
export const TeamConatiner = styled(Grid)({
  width: "100%",
  margin: " 30px auto 20px",
  display: "flex",
  justifyContent: "center",
});
export const TeamImageContainer = styled(Box)({
  width: "140px",
  height: "140px",
  borderRadius: "3px",
  margin: "10px auto",
  [theme.breakpoints.down("sm")]: {
    margin: "0px auto 0px",
  },
});
export const TeamImage = styled("img")({
  height: "100%",
  width: "100%",
  overflowClipMargin: "content-box",
  overflow: "clip",
  borderRadius: "3px",
  objectFit: "fill",
  filter: "grayscale(1)",
});
export const EmployeeName = styled("h5")(({ style }) => {
  return {
    textTransform: "uppercase",
    letterSpacing: ".2em",
    fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
    fontWeight: "normal",
    margin: "13px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "13px",
    ...style,
  };
});
//modal style
export const ModalContainer = styled(Box)({
  height: "60%",
  width: "40%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fbfbfb38;",
  boxShadow: 24,
  padding: "20px 40px 40px",
  opacity: 1,
  display: "grid",
  alignItems: "center",
});
export const ModalHeader = styled(Box)({
  height: "40px",
  width: "100%",
  display: "flex",
  margin: "0px 0px 20px",
  justifyContent: "end",
  alignItems: "center",
});
export const ModalLogo = styled("img")({
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "space-between",
});
export const ModalCloseIcon = styled(IconButton)({
  height: "35px",
  width: "35px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "8px",
  color: "#fff",

  ":hover": {
    backgroundColor: "hsla(0,0%,0%,0.2)",
    padding: "8px",
    borderRadius: "100%",
    color: "#fff",
  },
});
export const modalBodyContainerStyle = {
  height: "95%",
  width: "100%",
  display: "flex",
  alignItems: "center",
};
export const ModalBody = styled(Box)({
  height: "420px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  texAlign: "center",
});
export const ModalBodyContainer = styled(Box)({
  height: "100%",
});
export const ModalImageContainer = styled(Box)({
  height: "180px",
  width: "180px",
  borderRadius: "3px",
  marginBottom: "20px",
  margin: "auto",
});
export const ModalImage = styled("img")({
  height: "100%",
  width: "100%",
  overflowClipMargin: "content-box",
  overflow: "clip",
  borderRadius: "3px",
});
