import { Grid } from "@mui/material";
import React, { useState } from "react";
import { teamItems, teamLabel } from "../../../constants/team";
import { TeamConatiner } from "../../../styles/team";
import CustomHeadingWrapper from "../../shared/CustomHeadingWrapper";
import TeamImageWrapper from "./TeamImageWrapper";
import TeamModal from "./TeamModal";

export default function Team() {
  const [open, setOpen] = useState(false);
  const [employeeDatas, setEmployeeDatas] = useState({});

  const EmployeeDetailViewHandle = (EmployeeDatas) => {
    setEmployeeDatas(EmployeeDatas);
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          sx={{ m: "auto" }}
          xs={10}
          md={8}
          lg={10}
          data-aos="zoom-out-down"
          data-aos-duration="2000"
        >
          <CustomHeadingWrapper label={teamLabel} />
        </Grid>
        <TeamConatiner item xs={12} sx={12} md={10} lg={9} xl={8} m="auto">
          <Grid container sx={{ m: "auto" }}>
            {teamItems?.map((item, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  key={index}
                  data-aos="flip-right"
                  data-aos-duration="2000"
                >
                  <TeamImageWrapper
                    teamData={item}
                    onClick={() => EmployeeDetailViewHandle(item)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </TeamConatiner>
      </Grid>

      <TeamModal
        open={open}
        setOpen={setOpen}
        EmployeeData={employeeDatas || {}}
      />
    </>
  );
}
