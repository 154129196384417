import testimonial from "../assets/testimonialImages/testimonial-1.jpg";
import ranjit from "../assets/testimonialImages/ranjit.jpeg";
import krishnasamy from "../assets/testimonialImages/krishnasamy.jpg";
import marcMunro from "../assets/testimonialImages/marcMunro.jpeg";

export const testimonialsLabel = "Testimonials";

export const testimonialsItems = [
  {
    id: 1,
    name: "Ranjit",
    imgPath: ranjit,
    jobDescription: "Hippo Stores CTO",
    description: `We have worked with VHItech Staffing Services on multiple
    projects. We found all their team members extremely talented
    and committed. They understand how to leverage Agile
    development processes and need minimum oversight for project
    execution.`,
  },
  {
    id: 2,
    name: "Vahid",
    imgPath: testimonial,
    jobDescription: "Personilised AutoCare, California, United States",
    description: `HiTech Software developed a customized solution for our
    company from an early stage of concept to full development
    on time and on budget. After considering different software
    developers in Europe and India, I chose HiTech and could not
    be more satisfied with the outcome. We are in fact embarking
    on our second project now. What gave VHiTech the ability to
    perform at such a high level was their commitment to assign
    a core team and the Project Leader who clearly had the
    project management skills and area expertise to not only
    understand our requirement but also to recommend
    improvements as we went through the development process. The
    support team was always available, and we received responses
    to our inquiries and suggestions within 24 hours, and in
    many cases, the suggestions were analyzed, understood and
    implemented by the time we woke up in the US the next day.
    This was an impressively collaborative process. The result
    is a software tool that is as effective, and comprehensive,
    as originally planned.`,
  },
  {
    id: 3,
    name: "Jay Krishnasamy",
    imgPath: krishnasamy,
    jobDescription: "CEO",
    description: `We were looking for a tech savvy IT firm and Virdhi
    Technology delivered on that promise more than once. They
    built the online experience for 2 of our business
    properties. From the design/development to managing delivery
    to launching and maintaining our cloud instances, Virdhi
    Technology tech has been on top of their game. They
    communicated and kept the feedback loop very tight. They
    took the time to understand who we are as a company and
    always provided us with options to choose from, when it came
    to managing our future aspirations against cost of
    development. We wholeheartedly recommend Virdhi Technology
    to everyone we know.`,
  },
  {
    id: 4,
    name: "Abishek",
    imgPath: testimonial,
    jobDescription: "Business Analyst, Chrysalis",
    description: `I have to say that I'm very impressed. I know that this has
    taken a lot of time and effort, but this is a job
    excellently done. I'm proud to showcase this product to our
    schools. Our entire team is impressed with the work you guys
    have put in. Take a bow guys.`,
  },
  {
    id: 5,
    name: "Mike Munro",
    imgPath: marcMunro,
    jobDescription: "Principal and Co-Founder, GCMR",
    description: `I have had the pleasure of working with the VHiTech Team on a concept to completion software development project.   Their work is of the highest quality, and most helpful was their focus and outstanding advice relating to the success of the product, and business. I remember hearing many times, we succeed, if you succeed.   VHiTech truly is a company to partner with, for product ideas, enhancements, development and business success!`,
  },
];
