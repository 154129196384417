import styled from "@emotion/styled";
import { Box } from "@mui/system";
import backgroundImage from "../assets/backgroundImage.png";

export const LayoutContainer = styled(Box)({
  width: "100%",
  boxSizing: "border-box",
});
export const NavLayOutBgContainer = styled(Box)({
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#111",
  zIndex: 999,
  top: 0,
  left: 0,
  position: "sticky",
});
export const HomeLayOutBgContainer = styled(Box)({
  minHeight: "100vh",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#111",
  margin: "auto",
  display: "grid",
  alignItems: "center",
});
export const TechnologyExpertiseBgContainer = styled(Box)({
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#111",
  margin: "auto",
});
export const WhyChooseUsBgContainer = styled(Box)({
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#fff",
});
export const OurClientsBgContainer = styled(Box)({
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  backgroundColor: "#fff",
  paddingBottom: "30px",
});

export const centerStyle = {
  textAlign: "center",
  justifyContent: "center",
  display: "grid",
};
