export const navBarItems = [
  {
    id: 1,
    routeKey: "home",
    label: "Home",
    scrollValue: 0,
  },
  {
    id: 2,
    routeKey: "Why Choose Us",
    label: "whyChooseUs",
    scrollValue: 560,
  },
  {
    id: 3,
    routeKey: "Our Clients",
    label: "ourClients",
    scrollValue: 1420,
  },
  {
    id: 4,
    routeKey: "Testimonials",
    label: "testimonials",
    scrollValue: 2250,
  },
  {
    id: 5,
    routeKey: "Team",
    label: "team",
    scrollValue: 2830,
  },
  {
    id: 6,
    routeKey: "Contact",
    label: "contact",
    scrollValue: 4800,
  },
];
