import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import {
  ModalBody,
  ModalBodyContainer,
  modalBodyContainerStyle,
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalImage,
  ModalImageContainer,
} from "../../../styles/team";

const employeeNameStyle = {
  fontSize: "20px",
  fontWeight: "bolder",
  margin: "20px 13px 13px",
  color: "#111",
};

export default function TeamModal({ open, setOpen, EmployeeData }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        data-aos="zoom-out"
        data-aos-duration="1000"
      >
        <Fade
          in={open}
          sx={{
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <ModalContainer>
            <ModalHeader>
              <ModalCloseIcon onClick={() => setOpen(false)} size="small">
                <CloseIcon />
              </ModalCloseIcon>
            </ModalHeader>
            <Box sx={{ ...modalBodyContainerStyle }}>
              <ModalBody>
                <ModalBodyContainer>
                  <ModalImageContainer>
                    <ModalImage
                      src={EmployeeData?.icon || ""}
                      alt={EmployeeData?.name || ""}
                    />
                  </ModalImageContainer>
                </ModalBodyContainer>
              </ModalBody>
            </Box>
          </ModalContainer>
        </Fade>
      </Modal>
    </div>
  );
}
