import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import backgroundImage from "../assets/backgroundImage.png";
import theme from "./theme";

export const HomeLogoContainer = styled(Box)({
  padding: "20px 0px 0px",
  height: "100%",
  margin: "50px auto 0px",
  [theme.breakpoints.down("md")]: {
    padding: "20px 0px 10px",
    margin: "20px auto 0px",
  },
});
export const HomeText = styled(Box)(({ style }) => {
  return {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "26px",
    fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
    letterSpacing: "0.4em",
    textTransform: "uppercase",
    ...style,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  };
});
export const HomeLogo = styled(Box)({
  height: "200px",
  width: "100%",
  textAlign: "center",
  justifyContent: "center",
  margin: "30px auto 0px",
});
export const SpecificationContainer = styled(Grid)({
  width: "80%",
  margin: "auto",
});
export const SpecificationIconContainer = styled(Grid)({
  height: "90px",
  margin: "0px 10px",
  [theme.breakpoints.down("md")]: {
    margin: "20px auto 10px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "25px auto 20px",
  },
});
export const SpecificationLogo = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});
export const SpecificationText = styled(Box)({
  padding: "16px 0px",
  textAlign: "center",
  fontSize: "16px",
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  letterSpacing: "0.2em",
  borderRadius: "3px",
  color: "#fff",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
});
export const TechnologyExpertiseContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
export const HeadingText = styled(Box)(({ style }) => {
  return {
    // width: "30%",
    padding: "15px 25px",
    border: "1px solid #111",
    textAlign: "center",
    color: "#111",
    fontSize: "20px",
    margin: "50px auto",
    fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
    fontWeight: "normal",
    letterSpacing: "0.2em",
    borderRadius: "3px",
    ...style,
  };
});
export const teachnologyExpertiselabelStyle = {
  color: "#fff",
  border: "0px solid #fff",
  borderRadius: "0px",
  fontSize: "24px",
  margin: "55px auto 25px",
  padding: "0px 0px",
  letterSpacing: "0.4em",
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "22px !important",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "20px !important",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px !important",
  },
};
export const TechnologyExpertiseWrap = styled(Grid)({
  padding: "0px 100px 30px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px 30px",
  },
});
export const TechnologyExpertiseIconContainer = styled(Box)({
  height: "100px",
  width: "100px",
  padding: "30px 20px",
  margin: "15px auto 15px",
  boxSizing: "border-box",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
});
export const TechnologyExpertiseText = styled(Box)({
  fontSize: "16px",
  textAlign: "center",
  color: "#fff",
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  letterSpacing: "0.2em",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "10px",
    fontSize: "12px",
  },
});
