import { Grid } from "@mui/material";
import React from "react";
import { homeLogoPath } from "../../../constants/iconPath";
import { HomeLogo, HomeLogoContainer, HomeText } from "../../../styles/home";

export default function CompanyLogo() {
  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={9} sm={8} md={6}>
          <HomeLogoContainer>
            <HomeText data-aos="zoom-out" data-aos-duration="2000">
              We Provide Developers For
            </HomeText>
            <HomeText
              data-aos="zoom-out"
              data-aos-duration="2000"
              style={{ paddingTop: "22px" }}
            >
              Your Product With
            </HomeText>
          </HomeLogoContainer>
        </Grid>
      </Grid>
    </>
  );
}
