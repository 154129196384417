import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import theme from "./theme";
import backgroundImage from "../assets/backgroundImage.png";

export const navCenterStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const NavBarConatiner = styled(Box)({
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: "#111",
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: "repeat",
  zIndex: 999,
  top: 0,
  left: 0,
  position: "fixed",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
});
export const NavLogoContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  padding: "3px 15px",
});
export const Logo = styled("img")({
  width: "45px",
  height: "fit-content",
  cursor: "pointer",
});
export const NavBarListContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "5px 0px",
  justifyContent: "end",
});
export const NavItems = styled(Box)({
  fontFamily: "Dosis Helvetica Neue Helvetica sans-serif",
  fontWeight: "normal",
  height: "30px",
  padding: "0px 28px 0px 0px",
  margin: "0px 7px",
  textTransform: "uppercase",
  fontSize: "0.75rem",
  letterSpacing: ".2em",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});
