import styled from "@emotion/styled";
import { Box, Grid, MobileStepper } from "@mui/material";
import theme from "./theme";

export const TestimonialsBgContainer = styled(Box)({
  backgroundColor: "#fff",
});
export const TestimonialsConatiner = styled(Grid)({
  padding: "20px",
  margin: "20px",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
    margin: "0px",
  },
});
export const TestimonialCarouselContainer = styled(Box)({
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
});
export const TestimonialContentContainer = styled(Grid)({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
});
export const TestimonialImageContainer = styled(Grid)({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
});
export const TestimonialCarouselImage = styled("img")({
  width: "120px",
  borderRadius: "50%",
  border: "4px solid #fff",
  float: "left",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
});
export const TestimonialNameText = styled("h3")({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#111",
  fontFamily: "roboto",
});
export const TestimonialJobDescriptionText = styled("h3")({
  fontSize: "14px",
  margin: "0px 0 15px 0",
  color: "#999",
  fontWeight: 400,
});
export const TestimonialDescriptionText = styled("p")({
  fontFamily: "Rubik",
  wordSpacing: "1px",
  lineHeight: "1.3em",
  color: "rgb(16 16 16)",
});
export const TestimonialMobileStepper = styled(MobileStepper)({
  display: "flex",
  justifyContent: "center",
});
